define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-similar-topics", ["exports", "@ember/component", "@ember/runloop", "jquery", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _runloop, _jquery, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showTopics}}
    <ul>
      {{#each this.topics as |topic|}}
        <li>{{custom-wizard-similar-topic topic=topic}}</li>
      {{/each}}
    </ul>
  {{else}}
    <a role="button" class="show-topics" {{action "toggleShowTopics"}}>
      {{i18n "realtime_validations.similar_topics.show"}}
    </a>
  {{/if}}
  */
  {
    "id": "P4zA+He1",
    "block": "[[[41,[30,0,[\"showTopics\"]],[[[1,\"  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"topics\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[12],[1,[28,[35,5],null,[[\"topic\"],[[30,1]]]]],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[24,\"role\",\"button\"],[24,0,\"show-topics\"],[4,[38,7],[[30,0],\"toggleShowTopics\"],null],[12],[1,\"\\n    \"],[1,[28,[35,8],[\"realtime_validations.similar_topics.show\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"topic\"],false,[\"if\",\"ul\",\"each\",\"-track-array\",\"li\",\"custom-wizard-similar-topic\",\"a\",\"action\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-similar-topics.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNames: ["wizard-similar-topics"],
    showTopics: true,
    didInsertElement() {
      this._super(...arguments);
      (0, _jquery.default)(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      this._super(...arguments);
      (0, _jquery.default)(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      if (this._state === "destroying") {
        return;
      }
      let $target = (0, _jquery.default)(e.target);
      if (!$target.hasClass("show-topics")) {
        this.set("showTopics", false);
      }
    },
    toggleShowWhenTopicsChange() {
      this.set("showTopics", true);
    },
    actions: {
      toggleShowTopics() {
        this.set("showTopics", true);
      }
    }
  }, [["method", "toggleShowWhenTopicsChange", [(0, _decorators.observes)("topics")]]])));
});