define("discourse/plugins/discourse-custom-wizard/discourse/components/validator", ["exports", "@ember/component", "@ember/object/computed", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _computed, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isValid}}
    {{i18n this.validMessageKey}}
  {{else}}
    {{i18n this.invalidMessageKey}}
  {{/if}}
  */
  {
    "id": "xmDvKXum",
    "block": "[[[41,[30,0,[\"isValid\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"validMessageKey\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"invalidMessageKey\"]]],null]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/validator.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    classNames: ["validator"],
    classNameBindings: ["isValid", "isInvalid"],
    validMessageKey: null,
    invalidMessageKey: null,
    isValid: null,
    isInvalid: (0, _computed.equal)("isValid", false),
    init() {
      this._super(...arguments);
      if (this.get("validation.backend")) {
        // set a function that can be called as often as it need to
        // from the derived component
        this.backendValidate = params => {
          return (0, _ajax.ajax)("/realtime-validations", {
            data: {
              type: this.get("type"),
              ...params
            }
          });
        };
      }
    },
    didInsertElement() {
      this._super(...arguments);
      this.appEvents.on("custom-wizard:validate", this, this.checkIsValid);
    },
    willDestroyElement() {
      this._super(...arguments);
      this.appEvents.off("custom-wizard:validate", this, this.checkIsValid);
    },
    checkIsValid() {
      this.set("isValid", this.validate());
    }
  }));
});