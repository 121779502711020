define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-manager", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-admin"], function (_exports, _discourse, _customWizardAdmin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _customWizardAdmin.default.all();
    },
    setupController(controller, model) {
      controller.set("wizards", model);
    }
  });
});