define("discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/top-notices/prompt-completion", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @site.complete_custom_wizard as |wizard|}}
    <div class="row">
      <div class="alert alert-info alert-wizard">
        <a href={{wizard.url}}>{{i18n
            "wizard.complete_custom"
            name=wizard.name
          }}</a>
      </div>
    </div>
  {{/each}}
  */
  {
    "id": "MtePeKl8",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"complete_custom_wizard\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"alert alert-info alert-wizard\"],[12],[1,\"\\n      \"],[10,3],[15,6,[30,2,[\"url\"]]],[12],[1,[28,[35,4],[\"wizard.complete_custom\"],[[\"name\"],[[30,2,[\"name\"]]]]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[2]],null]],[\"@site\",\"wizard\"],false,[\"each\",\"-track-array\",\"div\",\"a\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/top-notices/prompt-completion.hbs",
    "isStrictMode": false
  });
});