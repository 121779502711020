define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-date", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-wizard-date-input
    date=this.date
    onChange=(action "onChange")
    tabindex=this.field.tabindex
    format=this.field.format
  }}
  */
  {
    "id": "gyFlXBvK",
    "block": "[[[1,[28,[35,0],null,[[\"date\",\"onChange\",\"tabindex\",\"format\"],[[30,0,[\"date\"]],[28,[37,1],[[30,0],\"onChange\"],null],[30,0,[\"field\",\"tabindex\"]],[30,0,[\"field\",\"format\"]]]]]]],[],false,[\"custom-wizard-date-input\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-date.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    setValue() {
      this.set("field.value", this.date.format(this.field.format));
    },
    actions: {
      onChange(value) {
        this.set("date", moment(value));
      }
    }
  }, [["method", "setValue", [(0, _decorators.observes)("date")]]])));
});