define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field", ["exports", "@ember/component", "@ember/string", "discourse/lib/text", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _string, _text, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label for={{this.field.id}} class="field-label">
    {{html-safe this.field.translatedLabel}}
  </label>
  
  {{#if this.field.image}}
    <div class="field-image"><img src={{this.field.image}} /></div>
  {{/if}}
  
  {{#if this.field.description}}
    <div class="field-description">{{this.cookedDescription}}</div>
  {{/if}}
  
  {{#field-validators field=this.field as |validators|}}
    {{#if this.inputComponentName}}
      <div class="input-area">
        {{component
          this.inputComponentName
          field=this.field
          step=this.step
          fieldClass=this.fieldClass
          wizard=this.wizard
          autocomplete=validators.autocomplete
        }}
      </div>
    {{/if}}
  {{/field-validators}}
  
  {{#if this.field.char_counter}}
    {{#if this.textType}}
      {{wizard-char-counter this.field.value this.field.max_length}}
    {{/if}}
  {{/if}}
  
  {{#if this.field.errorDescription}}
    <div class="field-error-description">{{html-safe
        this.field.errorDescription
      }}</div>
  {{/if}}
  */
  {
    "id": "u7Cq9fdU",
    "block": "[[[10,\"label\"],[15,\"for\",[30,0,[\"field\",\"id\"]]],[14,0,\"field-label\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"field\",\"translatedLabel\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"field\",\"image\"]],[[[1,\"  \"],[10,0],[14,0,\"field-image\"],[12],[10,\"img\"],[15,\"src\",[30,0,[\"field\",\"image\"]]],[12],[13],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"field\",\"description\"]],[[[1,\"  \"],[10,0],[14,0,\"field-description\"],[12],[1,[30,0,[\"cookedDescription\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[6,[39,5],null,[[\"field\"],[[30,0,[\"field\"]]]],[[\"default\"],[[[[41,[30,0,[\"inputComponentName\"]],[[[1,\"    \"],[10,0],[14,0,\"input-area\"],[12],[1,\"\\n      \"],[46,[30,0,[\"inputComponentName\"]],null,[[\"field\",\"step\",\"fieldClass\",\"wizard\",\"autocomplete\"],[[30,0,[\"field\"]],[30,0,[\"step\"]],[30,0,[\"fieldClass\"]],[30,0,[\"wizard\"]],[30,1,[\"autocomplete\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[1]]]]],[1,\"\\n\"],[41,[30,0,[\"field\",\"char_counter\"]],[[[41,[30,0,[\"textType\"]],[[[1,\"    \"],[1,[28,[35,7],[[30,0,[\"field\",\"value\"]],[30,0,[\"field\",\"max_length\"]]],null]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"],[41,[30,0,[\"field\",\"errorDescription\"]],[[[1,\"  \"],[10,0],[14,0,\"field-error-description\"],[12],[1,[28,[35,1],[[30,0,[\"field\",\"errorDescription\"]]],null]],[13],[1,\"\\n\"]],[]],null]],[\"validators\"],false,[\"label\",\"html-safe\",\"if\",\"div\",\"img\",\"field-validators\",\"component\",\"wizard-char-counter\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-field", "typeClasses", "field.invalid", "field.id"],
    didReceiveAttrs() {
      this._super(...arguments);
      (0, _text.cook)(this.field.translatedDescription).then(cookedDescription => {
        this.set("cookedDescription", cookedDescription);
      });
    },
    typeClasses: (type, id) => `${(0, _string.dasherize)(type)}-field ${(0, _string.dasherize)(type)}-${(0, _string.dasherize)(id)}`,
    fieldClass: id => `field-${(0, _string.dasherize)(id)} wizard-focusable`,
    inputComponentName(type, id) {
      if (["text_only"].includes(type)) {
        return false;
      }
      return (0, _string.dasherize)(type === "component" ? id : `custom-wizard-field-${type}`);
    },
    textType(fieldType) {
      return ["text", "textarea"].includes(fieldType);
    }
  }, [["field", "typeClasses", [(0, _decorators.default)("field.type", "field.id")]], ["field", "fieldClass", [(0, _decorators.default)("field.id")]], ["method", "inputComponentName", [(0, _decorators.default)("field.type", "field.id")]], ["method", "textType", [(0, _decorators.default)("field.type")]]])));
});