define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-date-time", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-wizard-date-time-input
    date=this.dateTime
    onChange=(action "onChange")
    tabindex=this.field.tabindex
  }}
  */
  {
    "id": "0guwkFlx",
    "block": "[[[1,[28,[35,0],null,[[\"date\",\"onChange\",\"tabindex\"],[[30,0,[\"dateTime\"]],[28,[37,1],[[30,0],\"onChange\"],null],[30,0,[\"field\",\"tabindex\"]]]]]]],[],false,[\"custom-wizard-date-time-input\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-date-time.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    setValue() {
      this.set("field.value", this.dateTime.format(this.field.format));
    },
    actions: {
      onChange(value) {
        this.set("dateTime", moment(value));
      }
    }
  }, [["method", "setValue", [(0, _decorators.observes)("dateTime")]]])));
});