define("discourse/plugins/discourse-custom-wizard/discourse/routes/custom-wizard", ["exports", "@ember/service", "discourse/routes/discourse", "I18n", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard"], function (_exports, _service, _discourse, _I18n, _customWizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    dialog: (0, _service.service)(),
    titleToken() {
      const wizard = this.modelFor("custom-wizard");
      return wizard ? wizard.name || wizard.id : _I18n.default.t("wizard.custom_title");
    },
    beforeModel(transition) {
      if (transition.intent.queryParams) {
        this.set("queryParams", transition.intent.queryParams);
      }
    },
    model(params) {
      return (0, _customWizard.findCustomWizard)(params.wizard_id, this.get("queryParams"));
    },
    showDialog(wizardModel) {
      const title = _I18n.default.t("wizard.incomplete_submission.title", {
        date: moment(wizardModel.submission_last_updated_at).format("MMMM Do YYYY")
      });
      const buttons = [{
        label: _I18n.default.t("wizard.incomplete_submission.restart"),
        class: "btn btn-default",
        action: () => {
          wizardModel.restart();
        }
      }, {
        label: _I18n.default.t("wizard.incomplete_submission.resume"),
        class: "btn btn-primary"
      }];
      this.dialog.dialog({
        title,
        buttons,
        type: "confirm"
      });
    },
    afterModel(model) {
      (0, _customWizard.updateCachedWizard)(model);
    },
    setupController(controller, model) {
      controller.setProperties({
        customWizard: true,
        logoUrl: this.siteSettings.logo_small,
        reset: null,
        model
      });
      const stepModel = this.modelFor("custom-wizard-step");
      if (model.resume_on_revisit && model.submission_last_updated_at && stepModel.index > 0) {
        this.showDialog(model);
      }
      const background = model.get("background");
      if (background) {
        document.body.style.background = background;
      }
    },
    activate() {
      if (!document.body.classList.contains("custom-wizard")) {
        document.body.classList.add("custom-wizard");
      }
    },
    deactivate() {
      if (document.body.classList.contains("custom-wizard")) {
        document.body.classList.remove("custom-wizard");
      }
      document.body.style.background = "";
    }
  });
});