define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-time-input", ["exports", "@ember/component", "discourse/components/time-input", "@ember/template-factory"], function (_exports, _component, _timeInput, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{combo-box
    value=this.time
    content=this.timeOptions
    tabindex=this.tabindex
    onChange=(action "onChangeTime")
    options=(hash
      translatedNone="--:--"
      allowAny=true
      filterable=false
      autoInsertNoneItem=false
      translatedFilterPlaceholder="--:--"
    )
  }}
  */
  {
    "id": "PJMGpO2F",
    "block": "[[[1,[28,[35,0],null,[[\"value\",\"content\",\"tabindex\",\"onChange\",\"options\"],[[30,0,[\"time\"]],[30,0,[\"timeOptions\"]],[30,0,[\"tabindex\"]],[28,[37,1],[[30,0],\"onChangeTime\"],null],[28,[37,2],null,[[\"translatedNone\",\"allowAny\",\"filterable\",\"autoInsertNoneItem\",\"translatedFilterPlaceholder\"],[\"--:--\",true,false,false,\"--:--\"]]]]]]]],[],false,[\"combo-box\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-time-input.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _timeInput.default.extend({}));
});