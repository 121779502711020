define("discourse/plugins/discourse-custom-wizard/discourse/mixins/undo-changes", ["exports", "@ember/object", "@ember/object/mixin", "discourse-common/lib/object", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema"], function (_exports, _object, _mixin, _object2, _wizard, _wizardSchema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const observedCache = [];
  var _default = _exports.default = _mixin.default.create({
    didInsertElement() {
      this._super(...arguments);
      this.setupObservers();
      const obj = this.get(this.componentType);
      this.setProperties({
        originalObject: JSON.parse(JSON.stringify(obj)),
        undoIcon: obj.isNew ? "times" : "undo",
        undoKey: `admin.wizard.${obj.isNew ? "clear" : "undo"}`
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      this.removeObservers();
    },
    removeObservers() {
      let objType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const componentType = this.componentType;
      const obj = this.get(componentType);
      let opts = {
        objectType: objType || obj.type
      };
      (0, _wizard.listProperties)(componentType, opts).forEach(property => {
        if (observedCache.includes(property)) {
          obj.removeObserver(property, this, this.toggleUndo);
          let index = observedCache.indexOf(property);
          if (index !== -1) {
            observedCache.splice(index, 1);
          }
        }
      });
    },
    setupObservers() {
      let objType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      const componentType = this.componentType;
      const obj = this.get(componentType);
      let opts = {
        objectType: objType || obj.type
      };
      (0, _wizard.listProperties)(componentType, opts).forEach(property => {
        if (observedCache.indexOf(property) === -1) {
          observedCache.push(property);
        }
        obj.addObserver(property, this, this.toggleUndo);
      });
    },
    revertToOriginal() {
      let revertBasic = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      const original = JSON.parse(JSON.stringify(this.originalObject));
      const componentType = this.componentType;
      const obj = this.get(componentType);
      const objSchema = _wizardSchema.default[componentType];
      const basicDefaults = objSchema.basic;
      if (revertBasic) {
        Object.keys(basicDefaults).forEach(property => {
          let value;
          if (original.hasOwnProperty(property)) {
            value = (0, _object.get)(original, property);
          } else if (basicDefaults.hasOwnProperty(property)) {
            value = (0, _object.get)(basicDefaults, property);
          }
          (0, _object.set)(obj, property, value);
        });
      }
      if (objSchema.types && obj.type) {
        let typeDefaults = objSchema.types[obj.type];
        Object.keys(typeDefaults).forEach(property => {
          let value;
          if (original.type === obj.type && original.hasOwnProperty(property)) {
            value = (0, _object.get)(original, property);
          } else if (typeDefaults.hasOwnProperty(property)) {
            value = (0, _object.get)(typeDefaults, property);
          }
          (0, _object.set)(obj, property, value);
        });
      }
    },
    toggleUndo() {
      const current = this.get(this.componentType);
      const original = this.originalObject;
      this.set("showUndo", !(0, _object2.deepEqual)(current, original));
    },
    actions: {
      undoChanges() {
        const componentType = this.componentType;
        const obj = this.get(componentType);
        this.removeObservers(obj.type);
        this.revertToOriginal(true);
        this.set("showUndo", false);
        this.setupObservers(this.get(componentType).type);
      },
      changeType(type) {
        const componentType = this.componentType;
        const original = this.get("originalObject");
        const obj = this.get(componentType);
        this.removeObservers(obj.type);
        obj.set("type", type);
        this.revertToOriginal();
        this.set("showUndo", type !== original.type);
        this.setupObservers(type);
      },
      // eslint-disable-next-line
      mappedFieldUpdated(property, mappedComponent, type) {
        const obj = this.get(this.componentType);
        obj.notifyPropertyChange(property);
      }
    }
  });
});