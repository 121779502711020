define("discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/admin-menu/wizards-nav-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.admin}}
    {{nav-item route="adminWizards" label="admin.wizard.nav_label"}}
  
    {{#if this.wizardErrorNotice}}
      {{d-icon "exclaimation-circle"}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "QRiRk8aL",
    "block": "[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"adminWizards\",\"admin.wizard.nav_label\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"wizardErrorNotice\"]],[[[1,\"    \"],[1,[28,[35,2],[\"exclaimation-circle\"],null]],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"nav-item\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/admin-menu/wizards-nav-button.hbs",
    "isStrictMode": false
  });
});