define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer-preview", ["exports", "@ember/component", "@ember/runloop", "jquery", "pretty-text/upload-short-url", "discourse/lib/ajax", "discourse/lib/load-oneboxes", "discourse-common/lib/debounce", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _runloop, _jquery, _uploadShortUrl, _ajax, _loadOneboxes, _debounce, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wizard-composer-preview d-editor-preview-wrapper">
    <div class="d-editor-preview">
      {{html-safe this.field.preview_template}}
    </div>
  </div>
  */
  {
    "id": "MRvAvMEH",
    "block": "[[[10,0],[14,0,\"wizard-composer-preview d-editor-preview-wrapper\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"d-editor-preview\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"field\",\"preview_template\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"html-safe\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-composer-preview.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    updatePreview() {
      if (this.isDestroyed) {
        return;
      }
      (0, _runloop.schedule)("afterRender", () => {
        if (this._state !== "inDOM" || !this.element) {
          return;
        }
        const $preview = (0, _jquery.default)(this.element);
        if ($preview.length === 0) {
          return;
        }
        this.previewUpdated($preview);
      });
    },
    previewUpdated($preview) {
      // Paint oneboxes
      const paintFunc = () => {
        (0, _loadOneboxes.loadOneboxes)($preview[0], _ajax.ajax, null, null, this.siteSettings.max_oneboxes_per_post, true // refresh on every load
        );
      };
      (0, _debounce.default)(this, paintFunc, 450);

      // Short upload urls need resolution
      (0, _uploadShortUrl.resolveAllShortUrls)(_ajax.ajax, this.siteSettings, $preview[0]);
    }
  }, [["method", "updatePreview", [(0, _decorators.on)("init")]]])));
});