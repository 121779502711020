define("discourse/plugins/discourse-custom-wizard/discourse/components/modal/admin-wizards-columns", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _object, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @closeModal={{@closeModal}} @title={{this.title}}>
    {{#if this.loading}}
      <LoadingSpinner size="large" />
    {{else}}
      <div class="edit-directory-columns-container">
        {{#each @model.columns as |column|}}
          <div class="edit-directory-column">
            <div class="left-content">
              <label class="column-name">
                <Input @type="checkbox" @checked={{column.enabled}} />
                {{directory-table-header-title
                  field=column.label
                  translated=true
                }}
              </label>
            </div>
          </div>
        {{/each}}
      </div>
    {{/if}}
    <div class="modal-footer">
      <DButton
        class="btn-primary"
        @label="directory.edit_columns.save"
        @action={{action "save"}}
      />
  
      <DButton
        class="btn-secondary reset-to-default"
        @label="directory.edit_columns.reset_to_default"
        @action={{action "resetToDefault"}}
      />
    </div>
  </DModal>
  */
  {
    "id": "8T1B9TPQ",
    "block": "[[[8,[39,0],null,[[\"@closeModal\",\"@title\"],[[30,1],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"    \"],[8,[39,2],[[24,\"size\",\"large\"]],null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"edit-directory-columns-container\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,2,[\"columns\"]]],null]],null],null,[[[1,\"        \"],[10,0],[14,0,\"edit-directory-column\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"left-content\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,0,\"column-name\"],[12],[1,\"\\n              \"],[8,[39,7],null,[[\"@type\",\"@checked\"],[\"checkbox\",[30,3,[\"enabled\"]]]],null],[1,\"\\n              \"],[1,[28,[35,8],null,[[\"field\",\"translated\"],[[30,3,[\"label\"]],true]]]],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n    \"],[8,[39,9],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\"],[\"directory.edit_columns.save\",[28,[37,10],[[30,0],\"save\"],null]]],null],[1,\"\\n\\n    \"],[8,[39,9],[[24,0,\"btn-secondary reset-to-default\"]],[[\"@label\",\"@action\"],[\"directory.edit_columns.reset_to_default\",[28,[37,10],[[30,0],\"resetToDefault\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"@model\",\"column\"],false,[\"d-modal\",\"if\",\"loading-spinner\",\"div\",\"each\",\"-track-array\",\"label\",\"input\",\"directory-table-header-title\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/modal/admin-wizards-columns.hbs",
    "isStrictMode": false
  });
  class AdminWizardsColumnComponent extends _component2.default {
    title = (() => _I18n.default.t("admin.wizard.edit_columns"))();
    save() {
      this.args.closeModal();
    }
    static #_ = (() => dt7948.n(this.prototype, "save", [_object.action]))();
    resetToDefault() {
      this.args.model.reset();
    }
    static #_2 = (() => dt7948.n(this.prototype, "resetToDefault", [_object.action]))();
  }
  _exports.default = AdminWizardsColumnComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminWizardsColumnComponent);
});