define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-container", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="wizard-subscription-container
      {{if this.subscription.subscribed 'subscribed'}}"
  >
    <div class="subscription-header">
      <h4>{{i18n "admin.wizard.subscription.title"}}</h4>
  
      <a href={{this.subscriptionLink}} title={{i18n this.subscribedTitle}}>
        {{d-icon this.subscribedIcon}}
        {{i18n this.subscribedLabel}}
      </a>
    </div>
  
    <div class="subscription-settings">
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "e3agyegd",
    "block": "[[[10,0],[15,0,[29,[\"wizard-subscription-container\\n    \",[52,[30,0,[\"subscription\",\"subscribed\"]],\"subscribed\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"subscription-header\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,[28,[35,3],[\"admin.wizard.subscription.title\"],null]],[13],[1,\"\\n\\n    \"],[10,3],[15,6,[30,0,[\"subscriptionLink\"]]],[15,\"title\",[28,[37,3],[[30,0,[\"subscribedTitle\"]]],null]],[12],[1,\"\\n      \"],[1,[28,[35,5],[[30,0,[\"subscribedIcon\"]]],null]],[1,\"\\n      \"],[1,[28,[35,3],[[30,0,[\"subscribedLabel\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"subscription-settings\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"div\",\"if\",\"h4\",\"i18n\",\"a\",\"d-icon\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-container.hbs",
    "isStrictMode": false
  });
  class WizardSubscriptionContainer extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "subscription", [_service.service]))();
    #subscription = (() => (dt7948.i(this, "subscription"), void 0))();
    get subscribedIcon() {
      return this.subscription.subscribed ? "check" : "times";
    }
    static #_2 = (() => dt7948.n(this.prototype, "subscribedIcon", [(0, _object.computed)("subscription.subscribed")]))();
    get subscribedLabel() {
      return `admin.wizard.subscription.${this.subscription.subscribed ? "subscribed" : "not_subscribed"}.label`;
    }
    static #_3 = (() => dt7948.n(this.prototype, "subscribedLabel", [(0, _object.computed)("subscription.subscribed")]))();
    get subscribedTitle() {
      return `admin.wizard.subscription.${this.subscription.subscribed ? "subscribed" : "not_subscribed"}.title`;
    }
    static #_4 = (() => dt7948.n(this.prototype, "subscribedTitle", [(0, _object.computed)("subscription.subscribed")]))();
  }
  _exports.default = WizardSubscriptionContainer;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WizardSubscriptionContainer);
});