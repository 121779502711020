define("discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-wizard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-wizard-controls">
    <ComboBox
      @value={{this.wizardListVal}}
      @content={{this.wizardList}}
      @onChange={{route-action "changeWizard"}}
      @options={{hash none="admin.wizard.select"}}
    />
    <DButton
      @action={{route-action "createWizard"}}
      @label="admin.wizard.create"
      @icon="plus"
    />
  </div>
  
  <WizardMessage
    @key={{this.messageKey}}
    @url={{this.messageUrl}}
    @component="wizard"
  />
  
  <div class="admin-wizard-container settings">
    {{outlet}}
  </div>
  */
  {
    "id": "UMLZPY1g",
    "block": "[[[10,0],[14,0,\"admin-wizard-controls\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"wizardListVal\"]],[30,0,[\"wizardList\"]],[28,[37,2],[\"changeWizard\"],null],[28,[37,3],null,[[\"none\"],[\"admin.wizard.select\"]]]]],null],[1,\"\\n  \"],[8,[39,4],null,[[\"@action\",\"@label\",\"@icon\"],[[28,[37,2],[\"createWizard\"],null],\"admin.wizard.create\",\"plus\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,5],null,[[\"@key\",\"@url\",\"@component\"],[[30,0,[\"messageKey\"]],[30,0,[\"messageUrl\"]],\"wizard\"]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"admin-wizard-container settings\"],[12],[1,\"\\n  \"],[46,[28,[37,7],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"div\",\"combo-box\",\"route-action\",\"hash\",\"d-button\",\"wizard-message\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/admin-wizards-wizard.hbs",
    "isStrictMode": false
  });
});