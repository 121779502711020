define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-tag", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-wizard-tag-chooser
    tags=this.field.value
    class=this.fieldClass
    tabindex=this.field.tabindex
    tagGroups=this.field.tag_groups
    everyTag=true
    options=(hash maximum=this.field.limit allowAny=this.field.can_create_tag)
  }}
  */
  {
    "id": "AMUKCe7u",
    "block": "[[[1,[28,[35,0],null,[[\"tags\",\"class\",\"tabindex\",\"tagGroups\",\"everyTag\",\"options\"],[[30,0,[\"field\",\"value\"]],[30,0,[\"fieldClass\"]],[30,0,[\"field\",\"tabindex\"]],[30,0,[\"field\",\"tag_groups\"]],true,[28,[37,1],null,[[\"maximum\",\"allowAny\"],[[30,0,[\"field\",\"limit\"]],[30,0,[\"field\",\"can_create_tag\"]]]]]]]]]],[],false,[\"custom-wizard-tag-chooser\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-tag.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({}));
});