define("discourse/plugins/discourse-custom-wizard/discourse/routes/custom-wizard-index", ["exports", "@ember/routing/route", "@ember/service", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard"], function (_exports, _route, _service, _customWizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    router: (0, _service.service)(),
    beforeModel() {
      const wizard = (0, _customWizard.getCachedWizard)();
      if (wizard && wizard.permitted && !wizard.completed && wizard.start) {
        this.router.replaceWith("customWizardStep", wizard.start);
      }
    },
    model() {
      return (0, _customWizard.getCachedWizard)();
    },
    setupController(controller, model) {
      if (model && model.id) {
        const completed = model.get("completed");
        const permitted = model.get("permitted");
        const wizardId = model.get("id");
        const user = model.get("user");
        const name = model.get("name");
        const requiresLogin = !user && !permitted;
        const notPermitted = !permitted;
        const props = {
          requiresLogin,
          user,
          name,
          completed,
          notPermitted,
          wizardId
        };
        controller.setProperties(props);
      } else {
        controller.set("noWizard", true);
      }
    }
  });
});