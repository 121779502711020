define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper", ["exports", "@ember/component", "@ember/array", "@ember/runloop", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-mapper", "@ember/template-factory"], function (_exports, _component, _array, _runloop, _decorators, _wizardMapper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.inputs as |input|}}
    {{#if input.connector}}
      {{wizard-mapper-connector
        connector=input.connector
        connectorType="input"
        onUpdate=(action "inputUpdated")
      }}
    {{/if}}
  
    {{wizard-mapper-input
      input=input
      options=this.inputOptions
      remove=(action "remove")
      onUpdate=(action "inputUpdated")
    }}
  {{/each}}
  
  {{#if this.canAdd}}
    <span class="add-mapper-input">
      {{d-button action=(action "add") label="admin.wizard.add" icon="plus"}}
    </span>
  {{/if}}
  */
  {
    "id": "7wBBL9rk",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"inputs\"]]],null]],null],null,[[[41,[30,1,[\"connector\"]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"connector\",\"connectorType\",\"onUpdate\"],[[30,1,[\"connector\"]],\"input\",[28,[37,4],[[30,0],\"inputUpdated\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[28,[35,5],null,[[\"input\",\"options\",\"remove\",\"onUpdate\"],[[30,1],[30,0,[\"inputOptions\"]],[28,[37,4],[[30,0],\"remove\"],null],[28,[37,4],[[30,0],\"inputUpdated\"],null]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"canAdd\"]],[[[1,\"  \"],[10,1],[14,0,\"add-mapper-input\"],[12],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"action\",\"label\",\"icon\"],[[28,[37,4],[[30,0],\"add\"],null],\"admin.wizard.add\",\"plus\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"input\"],false,[\"each\",\"-track-array\",\"if\",\"wizard-mapper-connector\",\"action\",\"wizard-mapper-input\",\"span\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNames: "wizard-mapper",
    didReceiveAttrs() {
      this._super();
      if (this.inputs && this.inputs.constructor !== Array) {
        (0, _runloop.later)(() => this.set("inputs", null));
      }
    },
    canAdd(inputs) {
      return !inputs || inputs.constructor !== Array || inputs.every(i => {
        return ["assignment", "association"].indexOf(i.type) === -1;
      });
    },
    inputOptions(options) {
      let result = {
        inputTypes: options.inputTypes || "assignment,conditional",
        inputConnector: options.inputConnector || "or",
        pairConnector: options.pairConnector || null,
        outputConnector: options.outputConnector || null,
        context: options.context || null,
        guestGroup: options.guestGroup || false,
        includeMessageableGroups: options.includeMessageableGroups || false,
        userLimit: options.userLimit || null
      };
      let inputTypes = ["key", "value", "output"];
      inputTypes.forEach(type => {
        result[`${type}Placeholder`] = options[`${type}Placeholder`] || null;
        result[`${type}DefaultSelection`] = options[`${type}DefaultSelection`] || null;
      });
      _wizardMapper.selectionTypes.forEach(type => {
        if (options[`${type}Selection`] !== undefined) {
          result[`${type}Selection`] = options[`${type}Selection`];
        } else {
          result[`${type}Selection`] = type === "text" ? true : false;
        }
      });
      return result;
    },
    onUpdate() {},
    actions: {
      add() {
        if (!this.get("inputs")) {
          this.set("inputs", (0, _array.A)());
        }
        this.get("inputs").pushObject((0, _wizardMapper.newInput)(this.inputOptions, this.inputs.length));
        this.onUpdate(this.property, "input");
      },
      remove(input) {
        const inputs = this.inputs;
        inputs.removeObject(input);
        if (inputs.length) {
          inputs[0].set("connector", null);
        }
        this.onUpdate(this.property, "input");
      },
      inputUpdated(component, type) {
        this.onUpdate(this.property, component, type);
      }
    }
  }, [["method", "canAdd", [(0, _decorators.default)("inputs.@each.type")]], ["method", "inputOptions", [(0, _decorators.default)("options.@each.inputType")]]])));
});