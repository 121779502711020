define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-wizard", ["exports", "@ember/controller", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _controller, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    creating: (0, _computed.equal)("wizardId", "create"),
    wizardListVal(creating, wizardId) {
      return creating ? null : wizardId;
    },
    messageKey(creating, wizardId) {
      let key = "select";
      if (creating) {
        key = "create";
      } else if (wizardId) {
        key = "edit";
      }
      return key;
    },
    messageUrl: "https://pavilion.tech/products/discourse-custom-wizard-plugin/documentation/"
  }, [["method", "wizardListVal", [(0, _decorators.default)("creating", "wizardId")]], ["method", "messageKey", [(0, _decorators.default)("creating", "wizardId")]]]));
});