define("discourse/plugins/discourse-custom-wizard/discourse/components/field-validators", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.field.validations}}
    {{#each-in this.field.validations.above as |type validation|}}
      {{component
        validation.component
        field=this.field
        type=type
        validation=validation
      }}
    {{/each-in}}
  
    {{yield (hash perform=(action "perform") autocomplete="off")}}
  
    {{#each-in this.field.validations.below as |type validation|}}
      {{component
        validation.component
        field=this.field
        type=type
        validation=validation
      }}
    {{/each-in}}
  {{else}}
    {{yield}}
  {{/if}}
  */
  {
    "id": "CexlJykn",
    "block": "[[[41,[30,0,[\"field\",\"validations\"]],[[[42,[28,[37,2],[[30,0,[\"field\",\"validations\",\"above\"]]],null],null,[[[1,\"    \"],[46,[30,1,[\"component\"]],null,[[\"field\",\"type\",\"validation\"],[[30,0,[\"field\"]],[30,2],[30,1]]],null],[1,\"\\n\"]],[1,2]],null],[1,\"\\n  \"],[18,5,[[28,[37,5],null,[[\"perform\",\"autocomplete\"],[[28,[37,6],[[30,0],\"perform\"],null],\"off\"]]]]],[1,\"\\n\\n\"],[42,[28,[37,2],[[30,0,[\"field\",\"validations\",\"below\"]]],null],null,[[[1,\"    \"],[46,[30,3,[\"component\"]],null,[[\"field\",\"type\",\"validation\"],[[30,0,[\"field\"]],[30,4],[30,3]]],null],[1,\"\\n\"]],[3,4]],null]],[]],[[[1,\"  \"],[18,5,null],[1,\"\\n\"]],[]]]],[\"validation\",\"type\",\"validation\",\"type\",\"&default\"],false,[\"if\",\"each\",\"-each-in\",\"component\",\"yield\",\"hash\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/field-validators.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    actions: {
      perform() {
        this.appEvents.trigger("custom-wizard:validate");
      }
    }
  }));
});