define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-api", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-api"], function (_exports, _service, _discourse, _customWizardApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    model() {
      return _customWizardApi.default.list();
    },
    setupController(controller, model) {
      const showParams = this.paramsFor("adminWizardsApiShow");
      const apiName = showParams.name === "create" ? null : showParams.name;
      const apiList = (model || []).map(api => {
        return {
          id: api.name,
          name: api.title
        };
      });
      controller.setProperties({
        apiName,
        apiList
      });
    },
    actions: {
      changeApi(apiName) {
        this.controllerFor("adminWizardsApi").set("apiName", apiName);
        this.router.transitionTo("adminWizardsApiShow", apiName);
      },
      afterDestroy() {
        this.router.transitionTo("adminWizardsApi").then(() => this.refresh());
      },
      afterSave(apiName) {
        this.refresh().then(() => this.send("changeApi", apiName));
      },
      createApi() {
        this.controllerFor("adminWizardsApi").set("apiName", "create");
        this.router.transitionTo("adminWizardsApiShow", "create");
      }
    }
  });
});