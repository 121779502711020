define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-links", ["exports", "@ember/component", "@ember/array", "@ember/object", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema", "@ember/template-factory"], function (_exports, _component, _array, _object, _computed, _decorators, _wizard, _wizardSchema, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wizard-header medium">{{html-safe (i18n this.header)}}</div>
  
  <div class="link-list">
    {{#if this.anyLinks}}
      {{#each this.links as |link|}}
        <div data-id={{link.id}}>
          {{d-button
            action=(action "change")
            actionParam=link.id
            translatedLabel=link.label
            class=link.classes
          }}
          {{#unless link.first}}
            {{d-button
              action=(action "back")
              actionParam=link
              icon="arrow-left"
              class="back"
            }}
          {{/unless}}
          {{#unless link.last}}
            {{d-button
              action=(action "forward")
              actionParam=link
              icon="arrow-right"
              class="forward"
            }}
          {{/unless}}
          {{d-button
            action=(action "remove")
            actionParam=link.id
            icon="times"
            class="remove"
          }}
        </div>
      {{/each}}
    {{/if}}
    {{d-button action=(action "add") label="admin.wizard.add" icon="plus"}}
  </div>
  */
  {
    "id": "5ETlYXzg",
    "block": "[[[10,0],[14,0,\"wizard-header medium\"],[12],[1,[28,[35,1],[[28,[37,2],[[30,0,[\"header\"]]],null]],null]],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"link-list\"],[12],[1,\"\\n\"],[41,[30,0,[\"anyLinks\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,0,[\"links\"]]],null]],null],null,[[[1,\"      \"],[10,0],[15,\"data-id\",[30,1,[\"id\"]]],[12],[1,\"\\n        \"],[1,[28,[35,6],null,[[\"action\",\"actionParam\",\"translatedLabel\",\"class\"],[[28,[37,7],[[30,0],\"change\"],null],[30,1,[\"id\"]],[30,1,[\"label\"]],[30,1,[\"classes\"]]]]]],[1,\"\\n\"],[41,[51,[30,1,[\"first\"]]],[[[1,\"          \"],[1,[28,[35,6],null,[[\"action\",\"actionParam\",\"icon\",\"class\"],[[28,[37,7],[[30,0],\"back\"],null],[30,1],\"arrow-left\",\"back\"]]]],[1,\"\\n\"]],[]],null],[41,[51,[30,1,[\"last\"]]],[[[1,\"          \"],[1,[28,[35,6],null,[[\"action\",\"actionParam\",\"icon\",\"class\"],[[28,[37,7],[[30,0],\"forward\"],null],[30,1],\"arrow-right\",\"forward\"]]]],[1,\"\\n\"]],[]],null],[1,\"        \"],[1,[28,[35,6],null,[[\"action\",\"actionParam\",\"icon\",\"class\"],[[28,[37,7],[[30,0],\"remove\"],null],[30,1,[\"id\"]],\"times\",\"remove\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null]],[]],null],[1,\"  \"],[1,[28,[35,6],null,[[\"action\",\"label\",\"icon\"],[[28,[37,7],[[30,0],\"add\"],null],\"admin.wizard.add\",\"plus\"]]]],[1,\"\\n\"],[13]],[\"link\"],false,[\"div\",\"html-safe\",\"i18n\",\"if\",\"each\",\"-track-array\",\"d-button\",\"action\",\"unless\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-links.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-links", "itemType"],
    items: (0, _array.A)(),
    anyLinks: (0, _computed.notEmpty)("links"),
    updateItemOrder(itemId, newIndex) {
      const items = this.items;
      const item = items.findBy("id", itemId);
      items.removeObject(item);
      item.set("index", newIndex);
      items.insertAt(newIndex, item);
    },
    header: itemType => `admin.wizard.${itemType}.header`,
    links(current, items) {
      if (!items) {
        return;
      }
      return items.map((item, index) => {
        if (item) {
          let link = {
            id: item.id
          };
          let label = item.label || item.title || item.id;
          if (this.generateLabels && item.type) {
            label = (0, _wizard.generateName)(item.type);
          }
          link.label = `${label} (${item.id})`;
          let classes = "btn";
          if (current && item.id === current.id) {
            classes += " btn-primary";
          }
          link.classes = classes;
          link.index = index;
          if (index === 0) {
            link.first = true;
          }
          if (index === items.length - 1) {
            link.last = true;
          }
          return link;
        }
      });
    },
    getNextIndex() {
      const items = this.items;
      if (!items || items.length === 0) {
        return 0;
      }
      const numbers = items.map(i => Number(i.id.split("_").pop())).sort((a, b) => a - b);
      return numbers[numbers.length - 1];
    },
    actions: {
      add() {
        const items = this.get("items");
        const itemType = this.itemType;
        let params = (0, _wizardSchema.setWizardDefaults)({}, itemType);
        params.isNew = true;
        params.index = this.getNextIndex();
        let id = `${itemType}_${params.index + 1}`;
        if (itemType === "field") {
          id = `${this.parentId}_${id}`;
        }
        params.id = id;
        let objectArrays = _wizardSchema.default[itemType].objectArrays;
        if (objectArrays) {
          Object.keys(objectArrays).forEach(objectType => {
            params[objectArrays[objectType].property] = (0, _array.A)();
          });
        }
        const newItem = _object.default.create(params);
        items.pushObject(newItem);
        this.set("current", newItem);
      },
      back(item) {
        this.updateItemOrder(item.id, item.index - 1);
      },
      forward(item) {
        this.updateItemOrder(item.id, item.index + 1);
      },
      change(itemId) {
        this.set("current", this.items.findBy("id", itemId));
      },
      remove(itemId) {
        const items = this.items;
        let item;
        let index;
        items.forEach((it, ind) => {
          if (it.id === itemId) {
            item = it;
            index = ind;
          }
        });
        let nextIndex;
        if (this.current.id === itemId) {
          nextIndex = index < items.length - 2 ? index + 1 : index - 1;
        }
        items.removeObject(item);
        if (nextIndex) {
          this.set("current", items[nextIndex]);
        }
      }
    }
  }, [["field", "header", [(0, _decorators.default)("itemType")]], ["method", "links", [(0, _decorators.default)("current", "items.@each.id", "items.@each.type", "items.@each.label", "items.@each.title")]]])));
});