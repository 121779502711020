define("discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-api", ["exports", "@ember/array", "@ember/object", "@ember/string", "discourse/lib/ajax", "discourse-common/utils/decorators"], function (_exports, _array, _object, _string, _ajax, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CustomWizardApi = _object.default.extend(dt7948.p({
    redirectUri(name) {
      let nameParam = (0, _string.dasherize)(name);
      const baseUrl = location.protocol + "//" + location.hostname + (location.port ? ":" + location.port : "");
      return baseUrl + `/admin/wizards/apis/${nameParam}/redirect`;
    }
  }, [["method", "redirectUri", [(0, _decorators.default)("name")]]]));
  CustomWizardApi.reopenClass({
    create() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const api = this._super.apply(this);
      const authorization = params.authorization || {};
      const endpoints = params.endpoints;
      api.setProperties({
        name: params.name,
        title: params.title,
        originalTitle: params.title,
        authType: authorization.auth_type,
        authUrl: authorization.auth_url,
        tokenUrl: authorization.token_url,
        clientId: authorization.client_id,
        clientSecret: authorization.client_secret,
        username: authorization.username,
        password: authorization.password,
        authParams: (0, _array.A)(authorization.auth_params),
        authorized: authorization.authorized,
        accessToken: authorization.access_token,
        refreshToken: authorization.refresh_token,
        code: authorization.code,
        tokenExpiresAt: authorization.token_expires_at,
        tokenRefreshAt: authorization.token_refresh_at,
        endpoints: (0, _array.A)(endpoints),
        isNew: params.isNew,
        log: params.log
      });
      return api;
    },
    find(name) {
      return (0, _ajax.ajax)(`/admin/wizards/api/${name}`, {
        type: "GET"
      }).then(result => {
        return CustomWizardApi.create(result);
      });
    },
    list() {
      return (0, _ajax.ajax)("/admin/wizards/api", {
        type: "GET"
      }).then(result => {
        return result;
      });
    }
  });
  var _default = _exports.default = CustomWizardApi;
});