define("discourse/plugins/discourse-custom-wizard/discourse/controllers/custom-wizard-step", ["exports", "@ember/controller", "@ember/service", "discourse-common/lib/get-url"], function (_exports, _controller, _service, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    router: (0, _service.service)(),
    wizard: null,
    step: null,
    actions: {
      goNext(response) {
        let nextStepId = response["next_step_id"];
        if (response.redirect_on_next) {
          window.location.href = response.redirect_on_next;
        } else if (response.refresh_required) {
          const wizardId = this.get("wizard.id");
          window.location.href = (0, _getUrl.default)(`/w/${wizardId}/steps/${nextStepId}`);
        } else {
          this.router.transitionTo("customWizardStep", nextStepId);
        }
      },
      goBack() {
        this.router.transitionTo("customWizardStep", this.get("step.previous"));
      },
      showMessage(message) {
        this.set("stepMessage", message);
      },
      resetWizard() {
        const id = this.get("wizard.id");
        const stepId = this.get("step.id");
        window.location.href = (0, _getUrl.default)(`/w/${id}/steps/${stepId}?reset=true`);
      }
    }
  });
});