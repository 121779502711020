define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-group", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-wizard-group-selector
    groups=this.site.groups
    class=this.fieldClass
    field=this.field
    whitelist=this.field.content
    value=this.field.value
    tabindex=this.field.tabindex
    onChange=(action (mut this.field.value))
    options=(hash none="select_kit.default_header_text")
  }}
  */
  {
    "id": "AZIJJNta",
    "block": "[[[1,[28,[35,0],null,[[\"groups\",\"class\",\"field\",\"whitelist\",\"value\",\"tabindex\",\"onChange\",\"options\"],[[30,0,[\"site\",\"groups\"]],[30,0,[\"fieldClass\"]],[30,0,[\"field\"]],[30,0,[\"field\",\"content\"]],[30,0,[\"field\",\"value\"]],[30,0,[\"field\",\"tabindex\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"field\",\"value\"]]],null]],null],[28,[37,3],null,[[\"none\"],[\"select_kit.default_header_text\"]]]]]]]],[],false,[\"custom-wizard-group-selector\",\"action\",\"mut\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-group.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({}));
});