define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-textarea", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Textarea
    id={{this.field.id}}
    @value={{this.field.value}}
    tabindex={{this.field.tabindex}}
    class={{this.fieldClass}}
    placeholder={{this.field.translatedPlaceholder}}
  />
  */
  {
    "id": "qZUuSOlu",
    "block": "[[[8,[39,0],[[16,1,[30,0,[\"field\",\"id\"]]],[16,\"tabindex\",[30,0,[\"field\",\"tabindex\"]]],[16,0,[30,0,[\"fieldClass\"]]],[16,\"placeholder\",[30,0,[\"field\",\"translatedPlaceholder\"]]]],[[\"@value\"],[[30,0,[\"field\",\"value\"]]]],null]],[],false,[\"textarea\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-textarea.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    keyPress(e) {
      e.stopPropagation();
    }
  }));
});