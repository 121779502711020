define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-number", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input
    id={{this.field.id}}
    step="0.01"
    @type="number"
    @value={{this.field.value}}
    tabindex={{this.field.tabindex}}
    class={{this.fieldClass}}
  />
  */
  {
    "id": "hgWlol6R",
    "block": "[[[8,[39,0],[[16,1,[30,0,[\"field\",\"id\"]]],[24,\"step\",\"0.01\"],[16,\"tabindex\",[30,0,[\"field\",\"tabindex\"]]],[16,0,[30,0,[\"fieldClass\"]]]],[[\"@type\",\"@value\"],[\"number\",[30,0,[\"field\",\"value\"]]]],null]],[],false,[\"input\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-number.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({}));
});