define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-user-selector", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-user-selector
    usernames=this.field.value
    placeholderKey=this.field.placeholder
    tabindex=this.field.tabindex
  }}
  */
  {
    "id": "rQT/K8HT",
    "block": "[[[1,[28,[35,0],null,[[\"usernames\",\"placeholderKey\",\"tabindex\"],[[30,0,[\"field\",\"value\"]],[30,0,[\"field\",\"placeholder\"]],[30,0,[\"field\",\"tabindex\"]]]]]]],[],false,[\"custom-user-selector\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-user-selector.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    classNameBindings: ["fieldClass"]
  }));
});