define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-dropdown", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{combo-box
    class=this.fieldClass
    value=this.field.value
    content=this.field.content
    tabindex=this.field.tabindex
    onChange=(action "onChangeValue")
    options=(hash none="select_kit.default_header_text")
  }}
  */
  {
    "id": "ysTrZIA8",
    "block": "[[[1,[28,[35,0],null,[[\"class\",\"value\",\"content\",\"tabindex\",\"onChange\",\"options\"],[[30,0,[\"fieldClass\"]],[30,0,[\"field\",\"value\"]],[30,0,[\"field\",\"content\"]],[30,0,[\"field\",\"tabindex\"]],[28,[37,1],[[30,0],\"onChangeValue\"],null],[28,[37,2],null,[[\"none\"],[\"select_kit.default_header_text\"]]]]]]]],[],false,[\"combo-box\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-dropdown.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    keyPress(e) {
      e.stopPropagation();
    },
    actions: {
      onChangeValue(value) {
        this.set("field.value", value);
      }
    }
  }));
});