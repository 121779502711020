define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-wizard", ["exports", "@ember/array", "@ember/object", "@ember/service", "rsvp", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/plugins/discourse-custom-wizard/discourse/lib/wizard-schema"], function (_exports, _array, _object, _service, _rsvp, _ajax, _discourse, _wizardSchema) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    model() {
      return (0, _ajax.ajax)("/admin/wizards/wizard");
    },
    afterModel(model) {
      (0, _wizardSchema.buildFieldTypes)(model.field_types);
      (0, _wizardSchema.buildFieldValidations)(model.realtime_validations);
      return (0, _rsvp.all)([this._getThemes(model), this._getApis(model), this._getUserFields(model)]);
    },
    _getThemes(model) {
      return (0, _ajax.ajax)("/admin/themes").then(result => {
        (0, _object.set)(model, "themes", result.themes.map(t => {
          return {
            id: t.id,
            name: t.name
          };
        }));
      });
    },
    _getApis(model) {
      return (0, _ajax.ajax)("/admin/wizards/api").then(result => (0, _object.set)(model, "apis", result));
    },
    _getUserFields(model) {
      return this.store.findAll("user-field").then(result => {
        if (result && result.content) {
          (0, _object.set)(model, "userFields", result.content.map(f => ({
            id: `user_field_${f.id}`,
            name: f.name
          })));
        }
      });
    },
    currentWizard() {
      const params = this.paramsFor("adminWizardsWizardShow");
      if (params && params.wizardId) {
        return params.wizardId;
      } else {
        return null;
      }
    },
    setupController(controller, model) {
      controller.setProperties({
        wizardList: model.wizard_list,
        wizardId: this.currentWizard(),
        custom_fields: (0, _array.A)(model.custom_fields.map(f => _object.default.create(f)))
      });
    },
    actions: {
      changeWizard(wizardId) {
        this.controllerFor("adminWizardsWizard").set("wizardId", wizardId);
        if (wizardId) {
          this.router.transitionTo("adminWizardsWizardShow", wizardId);
        } else {
          this.router.transitionTo("adminWizardsWizard");
        }
      },
      afterDestroy() {
        this.router.transitionTo("adminWizardsWizard").then(() => this.refresh());
      },
      afterSave(wizardId) {
        this.refresh().then(() => this.send("changeWizard", wizardId));
      },
      createWizard() {
        this.controllerFor("adminWizardsWizard").set("wizardId", "create");
        this.router.transitionTo("adminWizardsWizardShow", "create");
      }
    }
  });
});