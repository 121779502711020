define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-date-time-input", ["exports", "@ember/component", "discourse/components/date-time-input", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _dateTimeInput, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.timeFirst}}
    {{custom-wizard-date-input
      date=this.date
      relativeDate=this.relativeDate
      onChange=(action "onChangeDate")
      tabindex=this.dateTabindex
    }}
  {{/unless}}
  
  {{#if this.showTime}}
    {{custom-wizard-time-input
      date=this.date
      relativeDate=this.relativeDate
      onChange=(action "onChangeTime")
      tabindex=this.timeTabindex
    }}
  {{/if}}
  
  {{#if this.timeFirst}}
    {{custom-wizard-date-input
      date=this.date
      relativeDate=this.relativeDate
      onChange=(action "onChangeDate")
      tabindex=this.dateTabindex
    }}
  {{/if}}
  
  {{#if this.clearable}}
    {{d-button class="clear-date-time" icon="times" action=(action "onClear")}}
  {{/if}}
  */
  {
    "id": "+/HHA9MO",
    "block": "[[[41,[51,[30,0,[\"timeFirst\"]]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"date\",\"relativeDate\",\"onChange\",\"tabindex\"],[[30,0,[\"date\"]],[30,0,[\"relativeDate\"]],[28,[37,2],[[30,0],\"onChangeDate\"],null],[30,0,[\"dateTabindex\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showTime\"]],[[[1,\"  \"],[1,[28,[35,4],null,[[\"date\",\"relativeDate\",\"onChange\",\"tabindex\"],[[30,0,[\"date\"]],[30,0,[\"relativeDate\"]],[28,[37,2],[[30,0],\"onChangeTime\"],null],[30,0,[\"timeTabindex\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"timeFirst\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"date\",\"relativeDate\",\"onChange\",\"tabindex\"],[[30,0,[\"date\"]],[30,0,[\"relativeDate\"]],[28,[37,2],[[30,0],\"onChangeDate\"],null],[30,0,[\"dateTabindex\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"clearable\"]],[[[1,\"  \"],[1,[28,[35,5],null,[[\"class\",\"icon\",\"action\"],[\"clear-date-time\",\"times\",[28,[37,2],[[30,0],\"onClear\"],null]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"custom-wizard-date-input\",\"action\",\"if\",\"custom-wizard-time-input\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-date-time-input.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _dateTimeInput.default.extend(dt7948.p({
    classNameBindings: ["fieldClass"],
    timeTabindex(timeFirst, tabindex) {
      return timeFirst ? tabindex : tabindex + 1;
    },
    dateTabindex(timeFirst, tabindex) {
      return timeFirst ? tabindex + 1 : tabindex;
    }
  }, [["method", "timeTabindex", [(0, _decorators.default)("timeFirst", "tabindex")]], ["method", "dateTabindex", [(0, _decorators.default)("timeFirst", "tabindex")]]])));
});