define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-logs-show", ["exports", "@ember/array", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-logs"], function (_exports, _array, _service, _discourse, _customWizardLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    model(params) {
      return _customWizardLogs.default.list(params.wizardId);
    },
    afterModel(model) {
      if (model === null) {
        return this.router.transitionTo("adminWizardsLogs");
      }
    },
    setupController(controller, model) {
      controller.setProperties({
        wizard: model.wizard,
        logs: (0, _array.A)(model.logs),
        total: model.total
      });
    }
  });
});