define("discourse/plugins/discourse-custom-wizard/discourse/routes/admin-wizards-api-show", ["exports", "@ember/service", "discourse/routes/discourse", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-api"], function (_exports, _service, _discourse, _customWizardApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    router: (0, _service.service)(),
    model(params) {
      if (params.name === "create") {
        return _customWizardApi.default.create({
          isNew: true
        });
      } else {
        return _customWizardApi.default.find(params.name);
      }
    },
    afterModel(model) {
      if (model === null) {
        return this.router.transitionTo("adminWizardsApi");
      }
    },
    setupController(controller, model) {
      controller.set("api", model);
    }
  });
});