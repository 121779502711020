define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-topic", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-wizard-topic-selector
    topics=this.topics
    category=this.field.category
    onChange=(action "setValue")
    options=(hash maximum=this.field.limit)
  }}
  */
  {
    "id": "Lgfn2tzd",
    "block": "[[[1,[28,[35,0],null,[[\"topics\",\"category\",\"onChange\",\"options\"],[[30,0,[\"topics\"]],[30,0,[\"field\",\"category\"]],[28,[37,1],[[30,0],\"setValue\"],null],[28,[37,2],null,[[\"maximum\"],[[30,0,[\"field\",\"limit\"]]]]]]]]]],[],false,[\"custom-wizard-topic-selector\",\"action\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-topic.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    topics: [],
    didInsertElement() {
      this._super(...arguments);
      const value = this.field.value;
      if (value) {
        this.set("topics", value);
      }
    },
    actions: {
      setValue(_, topics) {
        if (topics.length) {
          this.set("field.value", topics);
        }
      }
    }
  }));
});