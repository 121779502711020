define("discourse/plugins/discourse-custom-wizard/discourse/controllers/admin-wizards-logs-show", ["exports", "@ember/controller", "@ember/object/computed", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-logs"], function (_exports, _controller, _computed, _decorators, _customWizardLogs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    refreshing: false,
    hasLogs: (0, _computed.notEmpty)("logs"),
    page: 0,
    canLoadMore: true,
    logs: [],
    messageKey: "viewing",
    loadLogs() {
      if (!this.canLoadMore) {
        return;
      }
      const page = this.get("page");
      const wizardId = this.get("wizard.id");
      this.set("refreshing", true);
      _customWizardLogs.default.list(wizardId, page).then(result => {
        this.set("logs", this.logs.concat(result.logs));
      }).finally(() => this.set("refreshing", false));
    },
    noResults(hasLogs, refreshing) {
      return !hasLogs && !refreshing;
    },
    actions: {
      loadMore() {
        if (!this.loadingMore && this.logs.length < this.total) {
          this.set("page", this.page += 1);
          this.loadLogs();
        }
      },
      refresh() {
        this.setProperties({
          canLoadMore: true,
          page: 0,
          logs: []
        });
        this.loadLogs();
      }
    }
  }, [["method", "noResults", [(0, _decorators.default)("hasLogs", "refreshing")]]]));
});