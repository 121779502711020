define("discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="wizard-column">
    <div class="wizard-column-contents">
      {{outlet}}
    </div>
    <div class="wizard-footer">
      {{#if this.customWizard}}
        <img
          src={{this.logoUrl}}
          style="background-image: initial; width: 33px; height: 33px;"
        />
      {{else}}
        <div class="discourse-logo"></div>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "IiT6Lxyc",
    "block": "[[[10,0],[14,0,\"wizard-column\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"wizard-column-contents\"],[12],[1,\"\\n    \"],[46,[28,[37,2],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"wizard-footer\"],[12],[1,\"\\n\"],[41,[30,0,[\"customWizard\"]],[[[1,\"      \"],[10,\"img\"],[15,\"src\",[30,0,[\"logoUrl\"]]],[14,5,\"background-image: initial; width: 33px; height: 33px;\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"discourse-logo\"],[12],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"component\",\"-outlet\",\"if\",\"img\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/custom-wizard.hbs",
    "isStrictMode": false
  });
});