define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-selector-type", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.item.label}}
  */
  {
    "id": "VYMD1/Eo",
    "block": "[[[1,[30,0,[\"item\",\"label\"]]]],[],false,[]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-mapper-selector-type.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    tagName: "a",
    classNameBindings: ["active"],
    active(type, activeType) {
      return type === activeType;
    },
    click() {
      this.toggle(this.item.type);
    }
  }, [["method", "active", [(0, _decorators.default)("item.type", "activeType")]]])));
});