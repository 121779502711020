define("discourse/plugins/discourse-custom-wizard/discourse/services/subscription", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PRODUCT_PAGE = "https://custom-wizard.pavilion.tech/pricing";
  const SUPPORT_MESSAGE = "https://coop.pavilion.tech/new-message?username=support&title=Custom%20Wizard%20Support";
  const MANAGER_CATEGORY = "https://coop.pavilion.tech/c/support/discourse-custom-wizard";
  class SubscriptionService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "subscribed", [_tracking.tracked], function () {
      return false;
    }))();
    #subscribed = (() => (dt7948.i(this, "subscribed"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "subscriptionType", [_tracking.tracked], function () {
      return "";
    }))();
    #subscriptionType = (() => (dt7948.i(this, "subscriptionType"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "businessSubscription", [_tracking.tracked], function () {
      return false;
    }))();
    #businessSubscription = (() => (dt7948.i(this, "businessSubscription"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "communitySubscription", [_tracking.tracked], function () {
      return false;
    }))();
    #communitySubscription = (() => (dt7948.i(this, "communitySubscription"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "standardSubscription", [_tracking.tracked], function () {
      return false;
    }))();
    #standardSubscription = (() => (dt7948.i(this, "standardSubscription"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "subscriptionAttributes", [_tracking.tracked], function () {
      return {};
    }))();
    #subscriptionAttributes = (() => (dt7948.i(this, "subscriptionAttributes"), void 0))();
    async init() {
      super.init(...arguments);
      await this.retrieveSubscriptionStatus();
    }
    async retrieveSubscriptionStatus() {
      let result = await (0, _ajax.ajax)("/admin/wizards/subscription").catch(_ajaxError.popupAjaxError);
      this.subscribed = result.subscribed;
      this.subscriptionType = result.subscription_type;
      this.subscriptionAttributes = result.subscription_attributes;
      this.businessSubscription = this.subscriptionType === "business";
      this.communitySubscription = this.subscriptionType === "community";
      this.standardSubscription = this.subscriptionType === "standard";
    }
    async updateSubscriptionStatus() {
      let result = await (0, _ajax.ajax)("/admin/wizards/subscription?update_from_remote=true").catch(_ajaxError.popupAjaxError);
      this.subscribed = result.subscribed;
      this.subscriptionType = result.subscription_type;
      this.subscriptionAttributes = result.subscription_attributes;
      this.businessSubscription = this.subscriptionType === "business";
      this.communitySubscription = this.subscriptionType === "community";
      this.standardSubscription = this.subscriptionType === "standard";
    }
    get subscriptionCtaLink() {
      switch (this.subscriptionType) {
        case "none":
          return PRODUCT_PAGE;
        case "standard":
          return SUPPORT_MESSAGE;
        case "business":
          return SUPPORT_MESSAGE;
        case "community":
          return MANAGER_CATEGORY;
        default:
          return PRODUCT_PAGE;
      }
    }
  }
  _exports.default = SubscriptionService;
});