define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-selector/wizard-subscription-selector-header", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "select-kit/components/select-kit/single-select-header", "@ember/template-factory"], function (_exports, _component, _object, _computed, _singleSelectHeader, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="select-kit-header-wrapper">
  
    {{component
      this.selectKit.options.selectedNameComponent
      tabindex=this.tabindex
      item=this.selectedContent
      selectKit=this.selectKit
      shouldDisplayClearableButton=this.shouldDisplayClearableButton
    }}
  
    {{#if this.subscriptionRequired}}
      <span class="subscription-label">{{i18n this.selectorLabel}}</span>
    {{/if}}
  
    {{d-icon this.caretIcon class="caret-icon"}}
  </div>
  */
  {
    "id": "XZLTCsOk",
    "block": "[[[10,0],[14,0,\"select-kit-header-wrapper\"],[12],[1,\"\\n\\n  \"],[46,[30,0,[\"selectKit\",\"options\",\"selectedNameComponent\"]],null,[[\"tabindex\",\"item\",\"selectKit\",\"shouldDisplayClearableButton\"],[[30,0,[\"tabindex\"]],[30,0,[\"selectedContent\"]],[30,0,[\"selectKit\"]],[30,0,[\"shouldDisplayClearableButton\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"subscriptionRequired\"]],[[[1,\"    \"],[10,1],[14,0,\"subscription-label\"],[12],[1,[28,[35,4],[[30,0,[\"selectorLabel\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[1,[28,[35,5],[[30,0,[\"caretIcon\"]]],[[\"class\"],[\"caret-icon\"]]]],[1,\"\\n\"],[13]],[],false,[\"div\",\"component\",\"if\",\"span\",\"i18n\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-selector/wizard-subscription-selector-header.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _singleSelectHeader.default.extend({
    classNames: ["combo-box-header", "wizard-subscription-selector-header"],
    caretUpIcon: (0, _computed.reads)("selectKit.options.caretUpIcon"),
    caretDownIcon: (0, _computed.reads)("selectKit.options.caretDownIcon"),
    caretIcon: (0, _object.computed)("selectKit.isExpanded", "caretUpIcon", "caretDownIcon", function () {
      return this.selectKit.isExpanded ? this.caretUpIcon : this.caretDownIcon;
    })
  }));
});