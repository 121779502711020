define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-no-access", ["exports", "@ember/component", "@ember/application", "@ember/string", "discourse/lib/cookie", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard", "@ember/template-factory"], function (_exports, _component, _application, _string, _cookie, _getUrl, _decorators, _customWizard, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>{{this.text}}</div>
  <div class="no-access-gutter">
    <a class="return-to-site" {{action "skip"}} role="button">
      {{i18n "wizard.return_to_site" siteName=this.siteName}}
    </a>
    {{#if this.showLoginButton}}
      <DButton
        class="btn-primary btn-small login-button"
        @action={{action "showLogin"}}
        @label="log_in"
        @icon="user"
      />
    {{/if}}
  </div>
  */
  {
    "id": "MYehh0av",
    "block": "[[[10,0],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n\"],[10,0],[14,0,\"no-access-gutter\"],[12],[1,\"\\n  \"],[11,3],[24,0,\"return-to-site\"],[24,\"role\",\"button\"],[4,[38,2],[[30,0],\"skip\"],null],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"wizard.return_to_site\"],[[\"siteName\"],[[30,0,[\"siteName\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showLoginButton\"]],[[[1,\"    \"],[8,[39,5],[[24,0,\"btn-primary btn-small login-button\"]],[[\"@action\",\"@label\",\"@icon\"],[[28,[37,2],[[30,0],\"showLogin\"],null],\"log_in\",\"user\"]],null],[1,\"\\n\"]],[]],null],[13]],[],false,[\"div\",\"a\",\"action\",\"i18n\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-no-access.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    classNameBindings: [":wizard-no-access", "reasonClass"],
    reasonClass(reason) {
      return (0, _string.dasherize)(reason);
    },
    siteName() {
      return this.siteSettings.title || "";
    },
    showLoginButton(reason) {
      return reason === "requiresLogin";
    },
    actions: {
      skip() {
        if (this.currentUser) {
          _customWizard.default.skip(this.get("wizardId"));
        } else {
          window.location = (0, _getUrl.default)("/");
        }
      },
      showLogin() {
        (0, _cookie.default)("destination_url", (0, _getUrl.default)(`/w/${this.get("wizardId")}`));
        (0, _application.getOwner)(this).lookup("route:application").send("showLogin");
      }
    }
  }, [["method", "reasonClass", [(0, _decorators.default)("reason")]], ["method", "siteName", [_decorators.default]], ["method", "showLoginButton", [(0, _decorators.default)("reason")]]])));
});