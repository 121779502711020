define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-status", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="supplier-authorize">
    <WizardSubscriptionBadge />
    {{#if this.authorized}}
      <ConditionalLoadingSpinner
        @condition={{this.unauthorizing}}
        @size="small"
      />
      <DButton
        class="deauthorize"
        @title="admin.wizard.subscription.deauthorize.title"
        @disabled={{this.unauthorizing}}
        @action={{this.deauthorize}}
      >
        {{i18n "admin.wizard.subscription.deauthorize.label"}}
      </DButton>
    {{else}}
      <DButton
        @icon="id-card"
        class="btn-primary"
        @label="admin.wizard.subscription.authorize.label"
        @title="admin.wizard.subscription.authorize.title"
        @action={{this.authorize}}
      />
    {{/if}}
  </div>
  */
  {
    "id": "sY2PSF7G",
    "block": "[[[10,0],[14,0,\"supplier-authorize\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[41,[30,0,[\"authorized\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@condition\",\"@size\"],[[30,0,[\"unauthorizing\"]],\"small\"]],null],[1,\"\\n    \"],[8,[39,4],[[24,0,\"deauthorize\"]],[[\"@title\",\"@disabled\",\"@action\"],[\"admin.wizard.subscription.deauthorize.title\",[30,0,[\"unauthorizing\"]],[30,0,[\"deauthorize\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,5],[\"admin.wizard.subscription.deauthorize.label\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@label\",\"@title\",\"@action\"],[\"id-card\",\"admin.wizard.subscription.authorize.label\",\"admin.wizard.subscription.authorize.title\",[30,0,[\"authorize\"]]]],null],[1,\"\\n\"]],[]]],[13]],[],false,[\"div\",\"wizard-subscription-badge\",\"if\",\"conditional-loading-spinner\",\"d-button\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-status.hbs",
    "isStrictMode": false
  });
  class WizardSubscriptionStatus extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "subscription", [_service.service]))();
    #subscription = (() => (dt7948.i(this, "subscription"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "supplierId", [_tracking.tracked], function () {
      return null;
    }))();
    #supplierId = (() => (dt7948.i(this, "supplierId"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "authorized", [_tracking.tracked], function () {
      return false;
    }))();
    #authorized = (() => (dt7948.i(this, "authorized"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "unauthorizing", [_tracking.tracked], function () {
      return false;
    }))();
    #unauthorizing = (() => (dt7948.i(this, "unauthorizing"), void 0))();
    basePath = "/admin/plugins/subscription-client/suppliers";
    constructor() {
      super(...arguments);
      (0, _ajax.ajax)(`${this.basePath}?resource=discourse-custom-wizard`).then(result => {
        if (result.suppliers && result.suppliers.length) {
          this.supplierId = result.suppliers[0].id;
          this.authorized = result.suppliers[0].authorized;
        }
      }).finally(() => {
        this.subscription.retrieveSubscriptionStatus();
      });
    }
    authorize() {
      window.location.href = `${this.basePath}/authorize?supplier_id=${this.supplierId}&final_landing_path=/admin/wizards/wizard`;
    }
    static #_6 = (() => dt7948.n(this.prototype, "authorize", [_object.action]))();
    deauthorize() {
      this.unauthorizing = true;
      (0, _ajax.ajax)(`${this.basePath}/authorize`, {
        type: "DELETE",
        data: {
          supplier_id: this.supplierId
        }
      }).then(result => {
        if (result.success) {
          this.supplierId = result.supplier_id;
          this.authorized = false;
        }
      }).finally(() => {
        this.unauthorizing = false;
        this.subscription.retrieveSubscriptionStatus();
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_7 = (() => dt7948.n(this.prototype, "deauthorize", [_object.action]))();
  }
  _exports.default = WizardSubscriptionStatus;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WizardSubscriptionStatus);
});