define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-category", ["exports", "@ember/component", "discourse/models/category", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _category, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{custom-wizard-category-selector
    categories=this.categories
    class=this.fieldClass
    whitelist=this.field.content
    onChange=(action (mut this.categories))
    tabindex=this.field.tabindex
    options=(hash maximum=this.field.limit)
  }}
  */
  {
    "id": "XUM7tlHG",
    "block": "[[[1,[28,[35,0],null,[[\"categories\",\"class\",\"whitelist\",\"onChange\",\"tabindex\",\"options\"],[[30,0,[\"categories\"]],[30,0,[\"fieldClass\"]],[30,0,[\"field\",\"content\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"categories\"]]],null]],null],[30,0,[\"field\",\"tabindex\"]],[28,[37,3],null,[[\"maximum\"],[[30,0,[\"field\",\"limit\"]]]]]]]]]],[],false,[\"custom-wizard-category-selector\",\"action\",\"mut\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-field-category.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    categories: [],
    didInsertElement() {
      this._super(...arguments);
      const property = this.field.property || "id";
      const value = this.field.value;
      if (value) {
        this.set("categories", [...value].reduce((result, v) => {
          let val = property === "id" ? _category.default.findById(v) : _category.default.findBySlug(v);
          if (val) {
            result.push(val);
          }
          return result;
        }, []));
      }
    },
    setValue() {
      const categories = (this.categories || []).filter(c => !!c);
      const property = this.field.property || "id";
      if (categories.length) {
        this.set("field.value", categories.reduce((result, c) => {
          if (c && c[property]) {
            result.push(c[property]);
          }
          return result;
        }, []));
      }
    }
  }, [["method", "setValue", [(0, _decorators.observes)("categories")]]])));
});